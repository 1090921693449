<template>
  <div>
    <div>
      <el-button v-throttle size="medium" @click="goBack">返回</el-button>
    </div>

    <div style="margin-top: 20px">
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="考场名称">
          <el-input
            v-model="queryParam.name"
            placeholder="请输入考场名称"
            @input="reloadCompanPlaceRoom"
          ></el-input>
        </el-form-item>

        <el-form-item label="代码">
          <el-input
            v-model="queryParam.code"
            placeholder="请输入代码"
            @input="reloadCompanPlaceRoom"
          ></el-input>
        </el-form-item>

        <el-form-item label="科目">
          <el-select
            v-model="queryParam.subjectType"
            placeholder="请选择科目"
            @change="reloadCompanPlaceRoom"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            icon="el-icon-refresh"
            @click="refresh"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-table
        v-loading="loading"
        border
        :data="companPlaceRoomList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="examPlaceName"
          label="所属考点"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="name"
          label="考场名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="code"
          label="代码"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="subjectType"
          label="科目"
          show-overflow-tooltip
          :min-width="30"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.subjectType == 1">物理</span>
            <span v-else-if="scope.row.subjectType == 2">生物</span>
            <span v-else-if="scope.row.subjectType == 3">化学</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="seatCount"
          label="座位数"
          show-overflow-tooltip
          :min-width="30"
        ></el-table-column>
        <el-table-column
          prop="seatArrange"
          label="座位布局"
          show-overflow-tooltip
          :min-width="30"
        >
          <template slot-scope="scope">
            <el-button
              v-throttle
              type="primary"
              size="mini"
              @click="seatArrange(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="mainMediaUrl"
          label="媒体流"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
    </div>

    <div style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageParam.pageIndex"
        :page-size="pageParam.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageParam.total"
      ></el-pagination>
    </div>

    <el-row>
      <el-dialog
        title="查看座位布局"
        :width="80 * this.seatArrangeResult.customColoums + 40 + 'px'"
        :visible.sync="dialogVisible"
        @close="close"
      >
        <div id="dialog-body">
          <div>
            座位数量:&nbsp;&nbsp;&nbsp;{{ seatArrangeResult.seatCount }}
          </div>
          <div>
            行:&nbsp;&nbsp;&nbsp;{{
              seatArrangeResult.customRows
            }}
            列:&nbsp;&nbsp;&nbsp;{{ seatArrangeResult.customColoums }}
          </div>
          <div id="platform-box">
            <span>讲台</span>
          </div>
          <div>
            <el-row
              v-for="(row, index) in seatArrangeResult.seatCodeArrange"
              :key="index"
              class="seat-row"
            >
              <el-col
                style="width: 80px"
                v-for="(coloum, index) in row"
                :key="index"
                class="seat-coloum"
              >
                <div class="seat-num">{{ coloum == 999 ? "" : coloum }}</div>
              </el-col>
            </el-row>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button
              v-throttle
              type="primary"
              size="medium"
              @click="dialogVisible = false"
              >关闭</el-button
            >
          </div>
        </div>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
import { getCompanPlaceRoomApi } from '@/api/schoolManufacturer/placeInfo.js'
import { isEmpty } from '@/utils/util.js'
export default {
  name: 'placeInfo',
  data() {
    return {
      urlParam: {
        examPlaceCode: '',
      },
      queryParam: {
        examPlaceCode: '',
        name: '',
        code: '',
        subjectType: '',
      },
      loading: false,
      companPlaceRoomList: [],
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      subjectOptions: [],
      seatArrangeResult: {},
      dialogVisible: false,
    }
  },
  mounted() {
    this.renderSelect()
    this.renderUrlParam()
  },
  methods: {
    renderSelect() {
      this.subjectOptions = this.$Global.globalSubjectOptions
    },
    renderUrlParam() {
      this.urlParam.examPlaceCode = this.$route.query.examPlaceCode
      this.queryParam.examPlaceCode = this.urlParam.examPlaceCode
      if (isEmpty(this.queryParam.examPlaceCode)) {
        return
      }
      this.getCompanPlaceRoom()
    },
    goBack() {
      this.$router.push({
        path: '/schoolManufacturer/baseData',
      })
    },
    //获取考场数据-分页
    getCompanPlaceRoom() {
      this.loading = true
      getCompanPlaceRoomApi(
        this.pageParam.pageIndex,
        this.pageParam.pageSize,
        this.queryParam
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.companPlaceRoomList = []
            this.companPlaceRoomList = res.data
            this.pageParam.total = 0
            this.pageParam.total = res.total
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    refresh() {
      this.queryParam.name = ''
      this.queryParam.code = ''
      this.queryParam.subjectType = ''
      this.reloadCompanPlaceRoom()
    },
    reloadCompanPlaceRoom() {
      this.pageParam.pageIndex = 1
      this.getCompanPlaceRoom()
    },
    handleCurrentChange(val) {
      this.pageParam.pageIndex = val
      this.getCompanPlaceRoom()
    },
    handleSizeChange(val) {
      this.pageParam.pageSize = val
      this.getCompanPlaceRoom()
    },
    //查看座位布局
    seatArrange(val) {
      console.log('查看座位布局', val)
      this.seatArrangeResult = {}
      this.seatArrangeResult = val
      if (this.seatArrangeResult.seatCodeArrange.length <= 0) {
        this.msgWarning('当前考点无座位布局信息')
        return
      }
      this.seatArrangeResult.customRows =
        this.seatArrangeResult.seatCodeArrange.length
      this.seatArrangeResult.customColoums =
        this.seatArrangeResult.seatCodeArrange[0].length
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.seatArrangeResult = {}
    },
  },
}
</script>
<style lang="scss" scoped>
#dialog-body {
  padding: 10px 0;
}
#dialog-body > div {
  margin-bottom: 15px;
  text-align: center;
}
#dialog-body > div:last-child {
  margin-bottom: 0px;
}
#platform-box > span {
  display: inline-block;
  width: 90px;
  height: 50px;
  line-height: 50px;
  border: 1px solid gainsboro;
}
.dialog-footer {
  text-align: center;
}
.seat-row {
  margin-bottom: 10px;
}
.seat-row:last-child {
  margin-bottom: 0px;
}
.seat-coloum {
  display: flex;
  justify-content: center;
  align-items: center;
}
.seat-num {
  width: 60px;
  height: 42px;
  line-height: 44px;
  border: 1px solid gainsboro;
}
</style>
